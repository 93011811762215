require('./bootstrap');

function notEmpty(){

var e = document.getElementById("width");
var strUser = e.options[e.selectedIndex].value;
document.getElementById('bredd-aggregator').innerHTML = strUser;
var e = document.getElementById("aspect_ratio");
var strUser = e.options[e.selectedIndex].value;
document.getElementById('profil-aggregator').innerHTML = strUser;
var e = document.getElementById("diameter");
var strUser = e.options[e.selectedIndex].value;
document.getElementById('tum-aggregator').innerHTML = strUser;

}
notEmpty()

document.getElementById("width").onchange = notEmpty;
document.getElementById("aspect_ratio").onchange = notEmpty;
document.getElementById("diameter").onchange = notEmpty;
